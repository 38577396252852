import React from 'react'
import { graphql, Script } from 'gatsby'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import FlexContentHandler from 'components/shared/FlexContentHandler'
import { Helmet } from 'react-helmet'
import parse from 'html-react-parser'

interface PageProps {
  data: {
    page: GatsbyTypes.WpPage
    educations: {
      nodes: GatsbyTypes.WpOpleiding[]
    }
    reviews: {
      nodes: GatsbyTypes.WpReview[]
    }
  }
  location?: any
  prefix?: string
}

export interface EducationContextProps {
  data?: GatsbyTypes.WpPage
}

// export const PageContext = React.createContext({})

const PageTemplate: React.FC<PageProps> = ({
  data: {
    page: { databaseId, flexContent, seo },
    educations,
    reviews,
  },
  location = {},
  prefix = 'page_Flexcontent',
}) => {
  return (
    // <PageContext.Provider value={databaseId}>
    <Layout fade={[2, 9].includes(databaseId)}>
      <SEO seo={seo} />
      {databaseId === 4605 && (
        <Helmet>
          <Script type="application/ld+json">
            {`
            {
              "@context":"https://schema.org",
              "@type":"ItemList",
              "itemListElement":[
                ${reviews.nodes.map(
                  (rev) =>
                    `{
                    "@context": "https://schema.org",
                    "@type": "Review",
                    "name": "${rev.title}",
                    "author": {
                      "@type": "Person",
                      "name": "${rev.recap?.person || 'Onbekend'}"ww
                    },
                    "itemReviewed": {
                      "@context": "https://schema.org/",
                      "@type": "Course",
                      "name": "${rev.recap?.relation?.[0]?.title}",
                      "description": "${
                        // @ts-ignore
                        rev.recap?.relation?.[0]?.previeweducation?.description?.replace(
                          /<[^>]*>/g,
                          ''
                        ) ||
                        rev.recap?.relation?.[0]?.title ||
                        ''
                      }",
                      "provider": {
                          "@type": "Organization",
                          "name": "Academie voor Psychodynamica",
                          "sameAs": "https://www.psychodynamica.nl/"
                      }
                    },
                    "description": "${
                      rev.recap?.description?.replace(/<[^>]*>/g, '') ||
                      rev.title ||
                      ''
                    }",
                    "provider": {
                        "@type": "Organization",
                        "name": "Academie voor Psychodynamica",
                        "sameAs": "https://www.psychodynamica.nl/"
                    }
                  }`
                )}
              ]
            }
            `}
          </Script>
        </Helmet>
      )}
      {databaseId === 349 && (
        <Helmet>
          <Script type="application/ld+json">
            {`
            {
              "@context":"https://schema.org",
              "@type":"ItemList",
              "itemListElement":[
                ${educations.nodes.map(
                  (edu) =>
                    `{
                    "@context": "${edu.uri}",
                    "@type": "Course",
                    "name": "${edu.title}",
                    "description": "${
                      edu.previeweducation?.description?.replace(
                        /<[^>]*>/g,
                        ''
                      ) ||
                      edu.title ||
                      ''
                    }",
                    "provider": {
                        "@type": "Organization",
                        "name": "Academie voor Psychodynamica",
                        "sameAs": "https://www.psychodynamica.nl/"
                    }
                  }`
                )}
              ]
            }
            `}
          </Script>
        </Helmet>
      )}
      <FlexContentHandler
        prefix={prefix}
        fields={flexContent}
        location={location}
      />
    </Layout>
    // </PageContext.Provider>
  )
}

export const pageQuery = graphql`
  query pageById($pageId: String!) {
    page: wpPage(id: { eq: $pageId }) {
      ...generalPageFragment
    }
    educations: allWpOpleiding(sort: { order: ASC, fields: date }) {
      nodes {
        ...generalOpleidingFragment
      }
    }
    reviews: allWpReview(sort: { order: DESC, fields: date }) {
      nodes {
        ...generalReviewFragment
      }
    }
  }
`

export default PageTemplate
